import React, { useState, useEffect, useRef } from "react"
import { graphql, withApollo, compose } from "react-apollo"
import {
    Button,
    Form,
    FormText,
    FormGroup,
    Label,
    Input,
    Popover,
    PopoverHeader,
    PopoverBody,
    Badge,
    Modal,
    ModalHeader,
    ModalBody,
    Progress,
} from 'reactstrap'
import { ActionTypesAndParamsQuery, ActionQuery, AllAvailableModelsQuery, AllTriggersQuery } from "./Queries"
import Select from "react-select"
import { UpdateActionMutation, CreateActionMutation } from "./Mutations"

const ActionForm = ({ actionTypesAndParams, action, allAvailableModels, allTriggers, handleClose, isOpen, isUpdate, loading, updateAction, createAction, actionId, automationId, refetchQuery, refetchAutomationQuery }) => {

    // value of the action type and param 
    const [actionValue, setActionValue] = useState({
        type: action && action.actionType || "",
        params: action && action.actionParams && JSON.parse(action.actionParams.replace(/'/g, '"')) || [],
    })
    const [triggerId, setTriggerId] = useState('')
    // value of the action query
    const [error, setError] = useState(null)
    const [availableModel, setAvailableModel] = useState("")
    const [stateloading, setStateLoading] = useState(false)

    const [actionParamsValues, setActionParamsValues] = useState({})
    // const [errors, setErrors] = useState({})
    const [openModal, setOpenModal] = useState("")
    const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
    const inputRefs = useRef({})

    const validateForm = () => {
        const newErrors = ""
        Object.keys(actionParamsValues).forEach((key) => {
            return actionParamsValues[key] === '' ? newErrors = newErrors + ` ${key} field is required. \n` : null
        })
        setError(newErrors)
        return newErrors.length === 0
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStateLoading(true)
        if (validateForm()) {
            console.log("Form is valid and ready for submission:", action)
            if (isUpdate) {

                updateAction({
                    variables: {
                        input: {
                            id: actionId,
                            actionType: action.type,
                            actionParams: JSON.stringify(actionParamsValues)
                        },
                    },
                }).then(({ data }) => {
                    if (data.updateAction.ok) {
                        handleClose()
                        refetchQuery()
                    } else {
                        setError(data.updateAction.errors[0].messages[0])
                    }
                })
            } else {
                createAction({
                    variables: {
                        input: {
                            automationId,
                            actionType: actionValue.type,
                            triggerId: triggerId,
                            actionParams: JSON.stringify(actionParamsValues),
                        }
                    }
                }).then(({ data }) => {
                    if (data.createAction.ok) {
                        handleClose()
                        refetchAutomationQuery()
                    } else {
                        setError(data.createAction.errors[0].messages[0])
                    }
                })
            }
            setStateLoading(false)
        }
    }


    const handleActionParamChange = (field, value, append) => {
        const newActionParamsValues = { ...actionParamsValues }
        newActionParamsValues[field] = append && newActionParamsValues[field] ? `${newActionParamsValues[field]}${value}` : value
        setActionParamsValues(newActionParamsValues)
    }
    const handleInstanceFieldClick = (paramName, field) => {
        let currentParamValues = Object.keys(actionParamsValues).some((key) => {
            return actionParamsValues[key] !== ''
        })
        let paramValues = isUpdate && !currentParamValues ? JSON.parse(action.actionParams.replace(/'/g, '"')) : actionParamsValues
        const newActionParamsValues = { ...paramValues }
        const inputRef = inputRefs.current[paramName]
        const value = newActionParamsValues[paramName]
        const newValue = `${value.slice(0, currentCursorPosition)}{{instance.${field}}}${value.slice(currentCursorPosition)}`
        newActionParamsValues[paramName] = newValue
        setActionParamsValues(newActionParamsValues)
        inputRef.focus()
        inputRef.setSelectionRange(currentCursorPosition + 11, currentCursorPosition + 11 + field.length)
    }

    useEffect(() => {
        if (action && allAvailableModels && actionTypesAndParams && actionTypesAndParams.length > 0) {
            let selectedActionTypeAndParam = actionTypesAndParams.filter((item) => item.actionType === action.actionType.toLowerCase())[0]
            setActionValue({ ...actionValue, type: selectedActionTypeAndParam.actionType.toUpperCase(), params: JSON.parse(selectedActionTypeAndParam.actionParams) })
            if (isUpdate) {
                setAvailableModel(allAvailableModels.edges.find((model) => model.node.contentType.model === action.trigger.contentType.model).node)
                setActionParamsValues(JSON.parse(action.actionParams.replace(/'/g, '"')))
            }
        }
    }, [actionTypesAndParams, action, allAvailableModels])

    useEffect(() => {
        // When actionTypes changes, set default values for actionParams
        if (!isUpdate && actionValue && actionValue.type && actionValue.params && actionValue.params.length > 0) {
            const defaultActionParams = {}
            actionValue.params.forEach((param) => {
                defaultActionParams[param.name] = param.default || ''
            })
            setActionParamsValues(defaultActionParams)
        }
    }, [actionValue])
    console.log("availableModel", availableModel, allTriggers,)
    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Action Detail</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                {(loading || stateloading) && <Progress animated color="info" value="100" />}
                <Form className="ActionForm" onSubmit={handleSubmit}>
                    <div>
                        <label>Action Type</label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                actionTypesAndParams && actionTypesAndParams.map((item) => ({
                                    label: item.actionType ? item.actionType.replaceAll('_', ' ').toUpperCase() : '',
                                    value: item,
                                }))
                            }
                            value={actionValue && actionValue.type && {
                                label: actionValue.type.replaceAll('_', ' ').toUpperCase(),
                                value: action,
                            }}
                            isDisabled={isUpdate}
                            placeholder="Select Trigger Type"
                            onChange={(option) => {
                                setActionValue({ ...actionValue, type: option.value.actionType, params: JSON.parse(option.value.actionParams) })
                            }}
                        />
                    </div>
                    <br />
                    {!isUpdate && <div>
                        <label>Select Trigger</label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                allTriggers && allTriggers.edges.map((item) => ({
                                    label: item.node.contentType.model + " | " + item.node.triggerType,
                                    value: item.node,
                                }))
                            }
                            value={availableModel && availableModel.contentType && {
                                label: availableModel.contentType.model.toUpperCase() + " | " + allTriggers.edges.find(trigger => trigger.node.contentType.model === availableModel.contentType.model).node.triggerType,
                                value: availableModel,
                            }}
                            isDisabled={isUpdate}
                            placeholder="Select Trigger Type"
                            onChange={(option) => {
                                setTriggerId(option.value.id)
                                setAvailableModel(allAvailableModels.edges.find((model) => model.node.contentType.model === option.value.contentType.model).node)
                            }}
                        />
                    </div>}
                    {actionValue && actionValue.type && actionValue.params && Array.isArray(actionValue.params) && actionValue.params.length > 0 && (
                        <div>
                            {actionValue.params.map((param) => (
                                <FormGroup key={param.name}>
                                    <Label for={param.name}>{param.name.toUpperCase()}{param.is_required && " *"}</Label>
                                    {param.description && <FormText color="muted">{param.description}</FormText>}
                                    {param.input === 'input' ? (
                                        <Input
                                            type="text"
                                            id={param.name + "actionParam"}
                                            defaultValue={action ? actionParamsValues[param.name] : ''}
                                            value={actionParamsValues && actionParamsValues[param.name]}
                                            required={param.is_required}
                                            onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                            onFocus={(e) => param.options_available && setOpenModal(`Popover${param.name}`)}
                                            onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                            innerRef={(el) => inputRefs.current[param.name] = el}
                                        />
                                    ) : param.input === 'textarea' ? (
                                        <Input
                                            type="textarea"
                                            id={param.name + "actionParam"}
                                            defaultValue={action ? actionParamsValues[param.name] : ''}
                                            value={actionParamsValues && actionParamsValues[param.name]}
                                            required={param.is_required}
                                            onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                            onFocus={(e) => param.options_available && setOpenModal(`Popover${param.name}`)}
                                            onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                            innerRef={(el) => inputRefs.current[param.name] = el}
                                        />
                                    ) : param.input === 'select' ? (
                                        <Input
                                            type="select"
                                            id={param.name + "actionParam"}
                                            value={actionParamsValues[param.name]}
                                            required={param.is_required}
                                            onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                        >
                                            {param.choices.map((choice) => (
                                                <option key={choice} value={choice}>{choice}</option>
                                            ))}
                                        </Input>
                                    ) : param.input === 'date' ? (
                                        <Input
                                            type="date"
                                            id={param.name + "actionParam"}
                                            defaultValue={action ? actionParamsValues[param.name] : ''}
                                            required={param.is_required}
                                            onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                            onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                            innerRef={(el) => inputRefs.current[param.name] = el}
                                        />
                                    ) : null}

                                    <Popover placement="bottom" target={param.name + "actionParam"} isOpen={openModal === `Popover${param.name}`} toggle={() => setOpenModal('')}>
                                        <PopoverHeader>Populate from {availableModel && `${availableModel.contentType.model}`} fields</PopoverHeader>
                                        <PopoverBody>
                                            {availableModel && availableModel.availableModelFields.map((field) => (
                                                <Badge
                                                    className="text-dark m-2 p-2" key={field}
                                                    style={{ cursor: "pointer", fontSize: 10 }} color="warning"
                                                    onClick={() => handleInstanceFieldClick(param.name, field)}>
                                                    {field.replaceAll('_', ' ').toUpperCase()}
                                                </Badge>
                                            ))}
                                        </PopoverBody>
                                    </Popover>
                                </FormGroup>
                            ))}
                        </div>
                    )}
                    {loading && <Progress animated color="info" value="100" />}
                    <Button type="submit" className="float-right">Submit</Button>
                </Form>

            </ModalBody>
        </Modal>
    )
}

export default compose(
    withApollo,
    graphql(ActionTypesAndParamsQuery, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { actionTypesAndParams } }) {
            return { actionTypesAndParams }
        },
    }),
    graphql(ActionQuery, {
        options: ({ actionId }) => ({ variables: { id: actionId } }),
        props({ data: { action, loading, refetch, variables } }) {
            return {
                action, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: ActionQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { action: fetchMoreResult.action }
                        },
                    })
                },
            }
        }
    }),
    graphql(AllAvailableModelsQuery, {
        options: () => ({
            variables: {},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { allAvailableModels } }) {
            return {
                allAvailableModels,
            }
        },
    }),
    graphql(AllTriggersQuery, {
        options: ({ automationId }) => ({
            variables: { automation: automationId },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allTriggers } }) {
            return {
                allTriggersloading: loading, allTriggers,
            }
        },
    }),
    graphql(CreateActionMutation, { name: 'createAction' }),
    graphql(UpdateActionMutation, { name: 'updateAction' }),
)(ActionForm)
