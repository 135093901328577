import gql from "graphql-tag"

const TransactionsQuery = gql`query TransactionsQuery($cursor: String,$first:Int!,$ids: [ID], $driverId:ID,$chargeTypeIds:[ID],$groupTypeIds:[ID],$includeDeleted:Boolean,$minAmount:Float,$maxAmount:Float,$eventDatetimeGte:Date,$eventDatetimeLte:Date, $dateAddedGte: Date, $dateAddedLte: Date,$notes:String, $orderBy:[String], $includeUpdates:Boolean, $loadDynamicValues: Boolean!) {
    transactions: allTransactions(first:$first,after: $cursor,driverId:$driverId, ids: $ids, chargeTypeIds:$chargeTypeIds,groupTypeIds:$groupTypeIds,includeDeleted:$includeDeleted,minAmount:$minAmount,maxAmount:$maxAmount, eventDatetimeGte: $eventDatetimeGte, eventDatetimeLte: $eventDatetimeLte, dateAddedGte: $dateAddedGte, dateAddedLte: $dateAddedLte, notes:$notes, orderBy: $orderBy, includeUpdates:$includeUpdates){
        edges{
            node{
                id
                pk
                dateAdded
                dueDate
                amount
                dynamicAmount @include(if: $loadDynamicValues)
                dynamicBalance @include(if: $loadDynamicValues)
                eventDatetime
                notes
                balance
                isDeleted
                isAssociatedWithPlan
                updateFor {
                    id
                    updateForPK: pk
                }
                chargeType{
                    id
                    name
                    paymentPlanCompatible
                }
                groupType{
                    id
                    name
                }
                agreement{
                    id
                    startDate
                    endDate
                    car {
                        id
                        carPK: pk
                    } 
                }
                status
                createdBy{
                    id
                    username
                }
                branch{
                    id
                    name
                }
            }
        }
        pageInfo{
            endCursor
            hasNextPage
        }
    } 
}`

const AllPaymentPlans = gql`query AllPaymentPlans($cursor: String,$first:Int!, $status:String, $searchTerm:String, $orderBy: [String]) {
    allPaymentPlans(first: $first,after: $cursor,status: $status, searchTerm:$searchTerm, orderBy:$orderBy){
        edges {
            node {
                id
                pk
                dateAdded
                paydownAmount
                paydownFrequency
                paydownOriginalBalance
                paydownStartDate
                paydownNotes
                reason
                status 
                addedBy {
                    id
                    username
                }
                driver {
                    id
                    name
                    phone
                    email
                    dmvLicense
                    tlcLicense
                    preferredLanguage    
                    branch{
                        id
                        name
                    }
                }
            }
        }
        pageInfo {
            length
            hasNextPage
            startCursor
            endCursor
        }
    } 
}`

const AllChargeTypes = gql`query AllChargeTypes{
    allChargeTypes(isActive:true,orderBy:["name"]){
        edges {
            node {
            id
            name
            category
            }
        }
        }
}`

const AllGroupTypes = gql`query AllGroupTypes{
    allGroupTypes(isActive:true,orderBy:["name"]){
        edges {
        node {
            id
            name
        }
        }
    }
}`

export { TransactionsQuery, AllChargeTypes, AllGroupTypes, AllPaymentPlans }
