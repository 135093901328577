import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress, Table, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
import { EnumQuery } from '../../Functions/querys'
import { TransactionsQuery } from "./Queries"
import Select from "react-select"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import "./PaymentPlan.css"
import { includes } from "lodash"

const CreatePaymentPlan = gql`mutation createPaymentPlan($input: CreatePaymentPlanMutationInput!){
    createPaymentPlan(input:$input){
        ok
        errors{
        messages
        }
    }
} `

class NewPaymentPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: { "intervalCount": 1, "paydownStartDate": moment().tz("America/New_York").format(moment.HTML5_FMT.DATE), "paydownNotes": "", "reason": "", transactions: this.props.selectedTransactions },
            error: null,
            loading: false
        }
    }
    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["driverId"] = this.props.driverId
            input[name] = value
            this.setState({ input: input, error: null })
        }
    }
    createPaymentPlan = (e) => {
        this.setState({ loading: true })
        let input = this.state.input
        this.props.client.mutate({
            mutation: CreatePaymentPlan,
            variables: { input },
        }).then((result) => {
            if (result && result.data && result.data.createPaymentPlan && result.data.createPaymentPlan.ok) {
                this.props.refetchQuery()
                this.props.handleClose()
            } else {
                let error = "An error occurred, could not complete request."
                if (result && result.data && result.data.createPaymentPlan && result.data.createPaymentPlan.errors[0] && result.data.createPaymentPlan.errors[0].messages) {
                    error = result.data.createPaymentPlan.errors[0].messages.toString()
                }
                this.setState({ error: error })
            }
            this.setState({ loading: false })
        }).catch((err) => {
            let error = "An error has occured"
            this.setState({ error: error, loading: false })
        })
    }

    updateTransactionAmount = (id, amount) => {
        let transactions = this.state.input.transactions
        let transaction = transactions.find(transaction => transaction.id === id)
        if (transaction) {
            transaction.amount = parseFloat(amount)
            this.setState({ input: { ...this.state.input, transactions: transactions }, error: null })
        }
    }

    validateTransactions = () => {
        let error = null
        if (this.props.transactions.edges.some(transaction => transaction.node.chargeType.paymentPlanCompatible === false)) {
            error = "Unable to create payment plan with transaction that is not payment plan compatible"
        } else if (this.props.transactions.edges.some(transaction => transaction.node.amount < 0)) {
            error = "Cannot create a paydown with a negative transaction"
        } else {
            let resultant_transactions = []
            this.props.transactions.edges.filter(transaction => this.props.selectedTransactions.includes(transaction.node.id)).forEach(transaction =>
                resultant_transactions.push({ id: transaction.node.id, amount: transaction.node.dynamicAmount })
            )
            this.setState({ input: { ...this.state.input, transactions: resultant_transactions } })
        }
        this.setState({ error: error })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions || (this.props.open && !prevProps.open)) {
            if (this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0) {
                this.validateTransactions()
            }
        }
    }

    componentDidMount() {
        if (this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0) {
            this.validateTransactions()
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="payment-plan">
                    <ModalHeader toggle={this.toggle}>Create Payment Plan</ModalHeader>
                    <ModalBody>
                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                        {this.props.loading ? <Col xs={12}><Progress style={{ marginTop: "10px", maxHeight: "5px" }} animated color="info" value="100" /></Col> :
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownOriginalBalance">Total amount driver is paying off</Label>
                                        <Input type={"number"} min={0} name="paydownOriginalBalance" id="paydownOriginalBalance" disabled value={this.state.input.transactions && this.state.input.transactions.length > 0 && this.state.input.transactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0)} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownStartDate">When will the paydown start?</Label>
                                        <Input type={"date"} name="paydownStartDate" id="paydownStartDate" onChange={this.updateInput} defaultValue={this.state.input.paydownStartDate} placeholder="Start date" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownAmount">Weekly paydown amount</Label>
                                        <Input type={"number"} name="paydownAmount" id="paydownAmount" disabled value={this.state.input.transactions && this.state.input.transactions.length > 0 && this.state.input.transactions.reduce((acc, transaction) => acc + parseFloat(transaction.amount), 0) / this.state.input.intervalCount} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="intervalCount">Intervals to split the amount</Label>
                                        <Input type={"number"} min={0} name="intervalCount" id="intervalCount" onChange={this.updateInput} defaultValue={this.state.input.intervalCount} placeholder="Enter intervals count" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="reason">Select paydown reason</Label>
                                        <Select
                                            className="bos-custom-select mt-2 mb-2"
                                            classNamePrefix="bos-select"
                                            name="reason"
                                            options={this.props.paymentPlanReasons && this.props.paymentPlanReasons.states.map(reason => ({ value: reason.description, label: reason.description }))}
                                            placeholder="Select Reason"
                                            onChange={(option) => this.setState({ input: { ...this.state.input, reason: option.value } })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="paydownNotes">Paydown notes</Label>
                                        <Input type={"text"} name="paydownNotes" id="paydownNotes" onChange={this.updateInput} defaultValue={this.state.input.paydownNotes} placeholder="Notes" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <Table responsive>
                                        <thead style={{ position: "sticky", top: -1, backgroundColor: "white" }}>
                                            <tr style={{ color: "black" }}>
                                                <th>ID</th>
                                                <th>Date Added</th>
                                                <th>Charge Type</th>
                                                <th>Due Date</th>
                                                <th>Amount</th>
                                                <th>Plan Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.loading && <tr><td colSpan={12}><Progress style={{ maxHeight: "5px" }} animated color="info" value="100" /></td></tr>}
                                            {this.props.transactions && this.props.transactions.edges.length ? this.props.transactions.edges.map((transaction, i) =>
                                                <tr key={i + 1} className={transaction.node.amount > 0 ? "danger" : "success"}>
                                                    <td className="no_decoration">
                                                        {transaction.node.pk}
                                                        {transaction.node.updateFor && <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{transaction.node.updateFor.updateForPK}</small></>}
                                                    </td>
                                                    <td className="no_decoration">{transaction.node.dateAdded ? <DatetimeRenderer datetime={transaction.node.dateAdded} format="llll" /> : "--"}</td>
                                                    <td className="shortText">
                                                        {transaction.node.chargeType ? transaction.node.chargeType.name : '--'}
                                                        <small>{transaction.node.groupType ? " (" + transaction.node.groupType.name + ")" : ''}</small>
                                                    </td>
                                                    <td className="no_decoration">{transaction.node.dueDate ? <DatetimeRenderer datetime={transaction.node.dueDate} format="ddd, MMM D, YYYY" /> : "--"}</td>
                                                    <td>
                                                        {parseFloat(transaction.node.amount) !== parseFloat(transaction.node.dynamicAmount) ? <>
                                                            <b id={"dynamicAmount" + i}>${transaction.node.dynamicAmount}*</b>
                                                            <UncontrolledTooltip placement="right" target={"dynamicAmount" + i}>${transaction.node.amount} {"->"} ${transaction.node.dynamicAmount}</UncontrolledTooltip>
                                                        </> : <>${transaction.node.amount}</>}
                                                    </td>
                                                    <td>
                                                        <Input type={"number"} min={1} max={transaction.node.dynamicAmount} onChange={(e) => this.updateTransactionAmount(transaction.node.id, e.target.value)} defaultValue={transaction.node.dynamicAmount} />
                                                    </td>
                                                </tr>) :
                                                !this.props.loading && <tr><td colSpan={12}>No Transaction Found</td></tr>}
                                            {this.props.loading && <tr><td colSpan={12}><Progress style={{ maxHeight: "5px" }} animated color="info" value="100" /></td></tr>}
                                            {this.props.transactions && this.props.transactions.pageInfo.hasNextPage &&
                                                <tr>
                                                    <td colSpan={6}><Button style={{ paddingLeft: 0 }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show older <i className={"fa fa-caret-right"}></i></Button></td>
                                                </tr>
                                            }
                                        </tbody >
                                    </Table >
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button className="payment-plan-modal-primary-btn" onClick={this.createPaymentPlan} disabled={this.state.error || this.state.loading || this.state.input.reason === "" || this.state.input.intervalCount <= 1}>{this.state.loading ? "Loading..." : "Create Payment Plan"}</Button>&nbsp;&nbsp;
                        <Button className="payment-plan-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { paymentPlanReasons } }) { return { paymentPlanReasons } } }),
    graphql(TransactionsQuery, {
        options: ({ selectedTransactions }) => ({
            variables: {
                ids: selectedTransactions,
                includeUpdates: true,
                loadDynamicValues: true,
                orderBy: ["-date_added"],
                first: 30
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, transactions, fetchMore, variables } }) {
            return {
                loading,
                transactions,
                variables,
                refetchTransactionsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = transactions && transactions.edges && transactions.edges.length > 30 ? transactions.edges.length : 30
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo
                            return {
                                transactions: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            cursor: transactions.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                transactions: {
                                    edges: [...previousResult.transactions.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(NewPaymentPlan)
